import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import ReportPage from './Components/ReportPage';
import CodePage from './Components/CodePage';
import EndPage from './Components/EndPage';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import strings from './Components/utils/Language';

function App(props) {

  strings.setLanguage(navigator.language);

  return (
    <Router>
        <Routes>
          <Route exac path="/" element={<Navigate to="/user/login" replace />}/>
          <Route exact path="/user/login" element={<LoginPage/>}/>
          <Route exact path="/user/report" element={<ReportPage/>}/>
          <Route exact path="/problem" element={<CodePage/>}/>
          <Route exact path="/end" element={<EndPage/>}/>
        </Routes>
  </Router>
  );

}

export default App;
