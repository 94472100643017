import React from "react";
import { Form, FormGroup, Alert } from "react-bootstrap";
import "../../styles/LoginForm.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import strings from "./Language";
import { axiosInstance } from "./AxiosInstance";

const LoginForm = ({ u, p }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(p);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [username, setUsername] = useState(u);

  function handleFormSubmit(e) {
    e.preventDefault();

    let bodyPayload = {
      grant_type: "password",
      username: username,
      password: password,
      scope: "",
      client_id: "",
      client_secret: "",
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axiosInstance
      .post("/api/v1/user/login", bodyPayload, axiosConfig)
      .then((response) => {
        localStorage.setItem("logged", true);
        localStorage.setItem("token", response.data.access_token);
        navigate("/user/report");
      })
      .catch(() => {
        setErrorOcurred(true);

        localStorage.setItem("logged", false);
      });
  }

  return (
    <div className="login_card">
      <div className="login">
        <Form className="LoginForm" id="loginForm">
          <h2>COGNITIVE LOAD EXPERIMENT</h2>
          <Alert id="login_error" variant="danger" show={errorOcurred}>
            {strings.login_error}
          </Alert>
          <FormGroup className="floating-label">
            <input
              autoComplete="on"
              className="login-input"
              id="username"
              value={username}
              type="text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <label>{strings.username}</label>
          </FormGroup>
          <FormGroup className="floating-label">
            <input
              autoComplete="on"
              className="login-input"
              id="password"
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>{strings.password}</label>
          </FormGroup>
          <FormGroup>
            <button
              type="submit"
              className="login-button"
              onClick={(e) => handleFormSubmit(e)}
            >
              {strings.login}
            </button>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
