import React from "react";
import { Navigate } from 'react-router-dom';
import  BasicInfoForm  from './utils/BasicInfoForm';

const ReportPage = () => {
    
    let logged = (localStorage.getItem("logged") === "true");

    if (!logged) {
        return <Navigate to="/user/login" />;
    }


    return (
        <BasicInfoForm />    
    );
    

}


export default ReportPage;