import React from 'react';
import LoginForm from './utils/LoginForm';
import { Navigate, useSearchParams } from 'react-router-dom';
const LoginPage = () => {

  const [searchParams] = useSearchParams();

  const username = searchParams.get("username") !== null ? searchParams.get("username") : '';
  const password = searchParams.get("password") !== null ? searchParams.get("password") : '';

  let logged = (localStorage.getItem("logged") === "true");

  if (logged) {
    return <Navigate to="/user/report" />;
  }

  return (
    <div>
      <LoginForm user u={username} p={password} />
    </div>
  );
  
}

export default LoginPage;
