import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  es: {
    understood: "Entendido",
    start_experiment: "Iniciar",
    error_sending: "Error al enviar el formulario al backend",
    error_validating: "Por favor, rellene todos los campos",
    login_error: "El nombre de usuario o la contraseña no son incorrectos",
    logout: "Logout",
    problem_error: "El problema no existe",
    accept_the_terms: "Marcando esta caja acepto los",
    terms_and_conditions: "Terminos y condiciones",
    username: "Nombre de usuario",
    password: "Contraseña",
    name: "Nombre y apellidos",
    login: "Login",
    back: "Volver",
    run: "Enviar",
    gender_male: "Hombre",
    gender_female: "Mujer",
    gender_nb: "No binario",
    gender_other: "Otro / Prefiero no especificar",
    gender: "Sexo",
    age: "Edad",
    instruction: "Instrucciones",
    instruction_description: ["contents"],
    study_level: "Nivel de estudios",
    coding_level: "Familiaridad con Python",
    xml_level: "Familiaridad con XML",
    cheatsheet: "Cheatsheet",
    cheatsheet_unknownQuantities:
      "Las cantidades desconocidas pueden ser un resultado o no, lo que se refleja en el campo type",
    cheatsheet_paths:
      "Los Paths establecen relaciones entre cantidades, el resultado debe ser el nombre de una cantidad, y los nodos una serie de nombres de cantidades separadas por coma, los tipos pueden ser Addition, Substraction, Multiplication, or Division",
    help: {
      introductionPythonXML:
        "La experiencia consiste en la generación del grafo de resolución de problemas de aritmética matemática básica. Recibirás 12 enunciados de problemas, para cada uno de los cuales deberás corregir, si es necesario, el código python, y completar el fichero XML. Para apoyar su respuesta, se proporciona el código python con la posible solución generada automáticamente a partir de modelos de lenguaje. Como el código ha sido generado automáticamente, puede haber algunos errores de lógica o nombrado de cantidades, así que corríjalos antes de enviar.",
      introductionPython:
        "La experiencia consiste en la generación del grafo de resolución de problemas de aritmética matemática básica. Recibirás 12 enunciados de problemas, para cada uno de los cuales deberás generar el código python. Para apoyar su respuesta, se proporciona el código python con la posible solución generada automáticamente a partir de modelos de lenguaje. Como se genera automáticamente, puede haber algunos errores de lógica o nombrado de cantidades, así que corríjalos antes de enviar.",
      introductionXML:
        "La experiencia consiste en la generación del grafo de resolución de problemas de aritmética matemática básica. Recibirás 12 enunciados de problemas, para cada uno de los cuales deberás generar el código xml. Se espera que rellenes los huecos del XML.",
      knownQuantitiesXML:
        "Las KnownQuantities tienen un nombre, que suele ser una o dos letras, y una descripción que es una frase corta.",
      unknownQuantitiesXML:
        'Las UnknownQuantities tienen un nombre, una descripción y un tipo, que es "result" o "" (cadena vacía).',
      pathsXML: [
        "Las trayectorias (Path) representan la relación entre las variables.",
        "El tipo de las trayectorias puede ser Addition, Multiplication, Division, or Substraction.",
        "El resultado hace referencia al nombre del nodo que es el resultado de la operación.",
        "nodes hace referencia a los nombres de los nodos que intervienen en la operación, separados por comas.",
      ],
      example: "A continuación se ofrece un ejemplo:",
      problemIsWrong:
        "Observe que el código mostrado no es correcto, ya que identifica erróneamente el tipo de relación.",
      completedXML: "El código XML completado tendría el siguiente aspecto:",
    },
    studies: {
      NO_STUDIES: "Sin estudios",
      PRIMARY: "Educación Primaria",
      SECONDARY: "Educación Secundaria",
      VOCATIONAL: "Estudios Vocacionales (FP)",
      GRADUATE: "Estudios de grado",
      MASTER: "Estudios de master",
      PHD: "Doctor",
    },
    RSME: {
      ABSOLUTELY_NO_EFFORT: "Absolutely no effort",
      ALMOST_NO_EFFORT: "Almost no effort",
      A_LITTLE_EFFORT: "A little effort",
      SOME_EFFORT: "Some effort",
      RATHER_MUCH_EFFORT: "Rather much effort",
      CONSIDERABLE_EFFORT: "Considerable effort",
      GREAT_EFFORT: "Great effort",
      VERY_GREAT_EFFORT: "Very great effort",
      EXTREME_EFFORT: "Extreme effort",
      REPORT_EFFORT: "How much effort did you make to complete this task?",
    },
    python: {
      basic: "Básico",
      intermediate: "Intermedio",
      advanced: "Avanzado",
    },
    REPORT_EFFORT: "Valora el esfuerzo que te ha llevado completar esta tarea",
    SEND: "Enviar",
    evaluation: "Evaluación",
    final: {
      header: "Gracias por su participación",
      text_before_email:
        "Sus respuestas han sido guardadas y serán analizadas para proseguir con nuestra investigación. Si lo desea, puede ejercer sus derechos de oposición o modificación de los datos poniendose en contacto con",
      email: "pablo.arnau@uv.es",
      text_after_email:
        "indicando el numero de participación que se muestra a continuación.",
    },
    legalText: {
      title: "Términos y Condiciones",
      content: "LoremIpsumwohfweojelwefjífwjewf",
    },
  },
  en: {
    understood: "Understood",
    start_experiment: "Begin",
    error_sending: "Error sending the form",
    error_validating: "Please, fill all the fields",
    login_error: "Username or password incorrect",
    logout: "Logout",
    problem_error: "Problem does not exist",
    accept_the_terms: "By checking this box I agree to the ",
    terms_and_conditions: "Terms and Conditions",
    username: "Username",
    password: "Password",
    name: "Name surname",
    login: "Login",
    back: "Back",
    run: "Submit",
    gender_male: "Man",
    gender_female: "Woman",
    gender_nb: "Non binary",
    gender_other: "Other / Prefer not disclose",
    gender: "Gender",
    age: "Age",
    study_level: "Level of studies",
    coding_level: "Familiarity with Python",
    xml_level: "Familiarity with XML",
    instruction: "Instructions",
    instruction_description: ["contenidos"],
    help: {
      introductionPythonXML:
        "The experience consists of the generation of the basic arithmetic mathematics problem-solving graph. You will receive 12 problem statements, for each of which you must correct, if required, the python code, and complete the XML file. To support your answer, python code is provided with the possible solution generated automatically from language models. As it is generated automatically, there may be some logic or quantity naming errors, so correct them before submitting.",
      introductionPython:
        "The experience consists of the generation of the basic arithmetic mathematics problem-solving graph. You will receive 12 problem statements, for each of which you must generate the python code. To support your answer, python code is provided with the possible solution generated automatically from language models. As it is generated automatically, there may be some logic or quantity naming errors, so correct them before submitting.",
      introductionXML:
        "The experience consists of the generation of the basic arithmetic mathematics problem-solving graph. You will receive 12 problem statements, for each of which you must generate the xml code. You are expected to fill the gaps in the XML.",
      knownQuantitiesXML:
        "KnownQuantities have a name, which is usually one or two letters, and a description which is a short sentence.",
      unknownQuantitiesXML:
        'UnknownQuantities hace a name, a description and a type, which is either result or "" (empty string).',
      pathsXML: [
        "Paths represent the relationship between the variables.",
        "The type can be either Addition, Multiplication, Division, or Substraction.",
        "The result references the node name that is the result of the operation.",
        "The nodes are the names of the nodes that are involved in the operation, comma-separated.",
      ],
      example: "An example is given below.",
      problemIsWrong:
        "Notice how the displayed code is not correct as it misidentifies the relation type.",
      completedXML: "The completed XML code would look like this:",
    },
    cheatsheet: "Cheatsheet",
    cheatsheet_unknownQuantities:
      "Unknown quantities can be a result or not, that is reflected in type",
    cheatsheet_paths:
      "Paths establish relationship between quantities, the result must be a quantity name, the nodes must be a series of quantity names separated by a comma, types can be Addition, Substraction, Multiplication, or Division",
    python: {
      basic: "Basic",
      intermediate: "Intermmediate",
      advanced: "Advanced",
    },
    studies: {
      NO_STUDIES: "No studies",
      PRIMARY: "Primary school",
      SECONDARY: "Secondary school / high school",
      VOCATIONAL: "Vocational Studies",
      GRADUATE: "Graduate / BSc / BEng / Similar",
      MASTER: "MSc / MEng / Similar",
      PHD: "Doctorate",
    },
    RSME: {
      ABSOLUTELY_NO_EFFORT: "Absolutely no effort",
      ALMOST_NO_EFFORT: "Almost no effort",
      A_LITTLE_EFFORT: "A little effort",
      SOME_EFFORT: "Some effort",
      RATHER_MUCH_EFFORT: "Rather much effort",
      CONSIDERABLE_EFFORT: "Considerable effort",
      GREAT_EFFORT: "Great effort",
      VERY_GREAT_EFFORT: "Very great effort",
      EXTREME_EFFORT: "Extreme effort",
    },
    REPORT_EFFORT: "How much effort did you make to complete this task?",
    SEND: "Send",
    evaluation: "Evaluation",
    final: {
      header: "Thank for your participation",
      text_before_email:
        "Your answers have been recorded and will be analysed continuing our research. If you wish, you can use your right to oppose or modify your data, you can do so by emailing",
      email: "pablo.arnau@uv.es",
      text_after_email: "indicating the participation number shown below.",
    },
    legalText: {
      title: "Terms and Conditions",
      content: "LoremIpsumwohfweojelwefjífwjewf",
    },
  },
});

export default strings;
