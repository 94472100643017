import React, { useCallback, useState } from "react";
import "../../styles/BasicInfoForm.css";
import { Form, Alert, FormGroup, Button, Row, Col } from "react-bootstrap";
import strings from "./Language";
import { axiosInstance } from "./AxiosInstance";
import { useNavigate } from "react-router-dom";
import InstructionsModal from "./InstructionsModal";
import TermsModal from "./TemsModal";

const BasicInfoForm = () => {
  const codingLevalOptions = [
    { value: "1", label: strings.python.basic },
    { value: "2", label: strings.python.intermediate },
    { value: "3", label: strings.python.advanced },
  ];

  const studyLevelOptions = [
    { value: "NO_STUDIES", label: strings.studies.NO_STUDIES },
    { value: "PRIMARY", label: strings.studies.PRIMARY },
    { value: "SECONDARY", label: strings.studies.SECONDARY },
    { value: "VOCATIONAL", label: strings.studies.VOCATIONAL },
    { value: "GRADUATE", label: strings.studies.GRADUATE },
    { value: "MASTER", label: strings.studies.MASTER },
    { value: "PHD", label: strings.studies.PHD },
  ];
  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [age, setAge] = useState("-1");
  const [studyLevel, setStudyLevel] = useState("-1");
  const [codingLevel, setCodingLevel] = useState("-1");
  const [xmlLevel, setXMLevel] = useState("-1");
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [showModalVisible, setShowModalVisible] = useState(true);
  const [checkboxIsClicked, setCheckboxIsClicked] = useState(false);

  const handleCheckedGender = (e) => {
    setGender(e.target.id);
  };

  const handleSelectedAge = (e) => {
    setAge(e.target.value);
  };

  const handleCheckedStudyLevel = (e) => {
    setStudyLevel(e.target.value);
  };

  const handleCheckedCodingLevel = (e) => {
    setCodingLevel(e.target.id);
  };
  const handleCheckedXMLLevel = (e) => {
    setXMLevel(e.target.id);
  };

  const validateForm = useCallback(
    (data) => {
      if (gender === "") return false;
      if (age === "-1") return false;
      if (studyLevel === "-1") return false;
      if (codingLevel === "-1") return false;
      if (xmlLevel === "-1") return false;
      if (name === "") return false;
      return checkboxIsClicked;
    },
    [gender, age, studyLevel, codingLevel, xmlLevel, checkboxIsClicked, name]
  );

  const handleFormSubmit = (e) => {
    let bodyPayload = {
      gender: gender,
      age: age,
      level_of_studies: studyLevel,
      python_dominion: codingLevel,
      xml_dominion: xmlLevel,
      name: name,
    };

    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    if (validateForm(bodyPayload)) {
      axiosInstance
        .post("/api/v1/report/", bodyPayload, axiosConfig)
        .then(() => {
          setOpenModal(true);
        })
        .catch((error) => {
          setErrorOcurred(true);
          let info = strings.error_sending; //"Se ha producido un error para enviar el formulario";
          setErrorInfo(info);
        });
    } else {
      setErrorOcurred(true);
      let info = strings.error_validating; //"Por favor, rellene todos los campos";
      setErrorInfo(info);
    }
  };

  const handleNavigation = () => {
    navigate("/problem");
  };

  return (
    <div className="form_background">
      <Row>
        <Col md="1">
          <FormGroup>
            <Button
              type="button"
              variant="danger"
              size="lg"
              onClick={() => {
                navigate("/end");
              }}
            >
              {strings.logout}
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <div className="form_container">
        <Form className="BasicInfoForm" id="BasicInfoForm">
          <Alert variant="danger" show={errorOcurred}>
            {errorInfo}
          </Alert>
          <FormGroup>
            <Form.Label>{strings.name}&nbsp;</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your name"
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Form.Label> {strings.gender}: </Form.Label>
            <Form.Check
              inline
              label={strings.gender_male}
              type="radio"
              name="group1"
              id="MALE"
              onChange={handleCheckedGender}
            />
            <Form.Check
              inline
              label={strings.gender_female}
              type="radio"
              name="group1"
              id="FEMALE"
              onChange={handleCheckedGender}
            />
            <Form.Check
              inline
              label={strings.gender_nb}
              type="radio"
              name="group1"
              id="OTHER"
              onChange={handleCheckedGender}
            />
            <Form.Check
              inline
              label={strings.gender_other}
              type="radio"
              name="group1"
              id="OTHER"
              onChange={handleCheckedGender}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label> {strings.age}: </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleSelectedAge}
            >
              <option value="-1">Seleccionar tu edad</option>
              <option value="0">&lt; 18</option>
              <option value="1">18 - 24</option>
              <option value="2">25 - 34</option>
              <option value="3">35 - 44</option>
              <option value="4">45 - 54</option>
              <option value="5">55 - 64</option>
              <option value="6">65+</option>
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Label> {strings.study_level}: </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleCheckedStudyLevel}
            >
              <option value="-1">Seleccionar tu nivel</option>
              {studyLevelOptions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </FormGroup>

          <FormGroup>
            <Form.Label> {strings.coding_level}: </Form.Label>
            {codingLevalOptions.map((option, i) => (
              <Form.Check
                key={i}
                inline
                label={option.label}
                name="py"
                type="radio"
                id={option.value}
                onChange={handleCheckedCodingLevel}
              />
            ))}
          </FormGroup>
          <FormGroup>
            <Form.Label> {strings.xml_level}: </Form.Label>
            {codingLevalOptions.map((option, i) => (
              <Form.Check
                key={i}
                inline
                label={option.label}
                name="xml"
                type="radio"
                id={option.value}
                onChange={handleCheckedXMLLevel}
              />
            ))}
          </FormGroup>
          <FormGroup>
            <Form.Check
              type="checkbox"
              onClick={(e) => setCheckboxIsClicked(e.target.value === "on")}
            />
            <Form.Label>
              {strings.accept_the_terms} &nbsp;
              <a
                href="#gpdr"
                onClick={() => {
                  setShowModalVisible(true);
                }}
              >
                {strings.terms_and_conditions}
              </a>
            </Form.Label>
          </FormGroup>
          <FormGroup>
            <button
              type="button"
              className="login-button"
              onClick={handleFormSubmit}
            >
              {strings.start_experiment}
            </button>
          </FormGroup>
        </Form>
        <TermsModal
          show={showModalVisible}
          onHide={() => {
            setShowModalVisible(false);
          }}
        />
        <InstructionsModal
          openModal={openModal}
          handleNavigation={handleNavigation}
        />
      </div>
    </div>
  );
};

export default BasicInfoForm;
