import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";
import axiosInstance from "./AxiosInstance";

import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import strings from "./Language";
function getMarks() {
  const marks = {
    0: strings.RSME.ABSOLUTELY_NO_EFFORT,
    100: strings.RSME.EXTREME_EFFORT,
  };

  let mapped_object = {};
  Object.keys(marks).forEach((key) => {
    mapped_object[key] = {
      style: { "white-space": "nowrap" },
      label: marks[key],
    };
  });

  return mapped_object;
}
const PopupWindow = (props) => {
  const initialValue = -1;
  const navigate = useNavigate();
  const [value, setValue] = useState(initialValue);

  const style = {
    height: "10vh",
  };

  const handleFormSubmit = (event) => {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let object = { rsme: value };
    console.log(object);
    let bodyPayload = {
      response_id: props.responseid,
      assesment: JSON.stringify(object),
    };

    axiosInstance
      .post("/api/v1/assessment/", bodyPayload, axiosConfig)
      .then(() => {
        console.log("assessment sent");
        props.onHide(false);
        navigate("/problem");
        navigate(0);
      });
  };

  return (
    <Modal
      {...props}
      size="auto"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {strings.evaluation}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <p>{strings.REPORT_EFFORT}</p>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div style={style}>
                <Slider
                  min={0}
                  max={100}
                  marks={getMarks()}
                  included={false}
                  defaultValue={50}
                  onChange={setValue}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleFormSubmit} disabled={value === initialValue}>
          {strings.SEND}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupWindow;
