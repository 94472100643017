import React, { useEffect } from "react";
import strings from "./utils/Language";
import axiosInstance from "./utils/AxiosInstance";

const EndPage = () => {
  const [participationNumber, setParticipationNumber] = React.useState("");

  useEffect(() => {
    axiosInstance
      .get("/api/v1/user/participation-code", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setParticipationNumber(response.data.participation);
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <h1>{strings.final.header}</h1>
      <p>
        {strings.final.text_before_email}&nbsp;
        <a href={`mailto:${strings.final.email}`}>{strings.final.email}</a>{" "}
        &nbsp;
        {strings.final.text_after_email}
      </p>
      <h2>NUMERO DE PARTICIPACIÓN: {participationNumber}</h2>
    </div>
  );
};

export default EndPage;
