let AppConfig = {
  url: "",
};

if (process.env.NODE_ENV === "development")
  AppConfig.url = "http://localhost:8080";
// AppConfig.url = 'http://127.0.0.1:5000';
// AppConfig.url = "http://marerouter.uv.es";
//AppConfig.url = 'http://147.156.87.9:8080';

export default AppConfig;
