import Modal from "react-bootstrap/Modal";
import strings from "./Language";
import axiosInstance from "./AxiosInstance";
import { useEffect, useState } from "react";
import "highlight.js/styles/github.css";
import Highlight from "react-highlight";
const examplePythonCode =
  "def sol():\n" +
  "    context = dict()\n" +
  "    context['number of girls'] = 732\n" +
  "    context['number of boys'] = 761\n" +
  "    context['number of girls more'] = 682\n" +
  "    context['number of boys more'] = 8\n" +
  "    context['number of girls now'] = context['number of girls'] - context['number of girls more']\n" +
  "    return context['number of girls now']";
const exampleXMLCode =
  '<xml version="1.0" encoding="UTF-8">\n' +
  '<Exercise name="a_given_name">\n' +
  "    <Text>\n" +
  "        In a school there are 732 girls and 761 boys.\n" +
  "        682 more girls and 8 more boys joined the school.\n" +
  "        How many girls are there in the school now?\n" +
  "    </Text>\n" +
  '    <KnownQuantity name=" ... " value="732">\n' +
  "        <Description> ... </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name=" ... " value="761">\n' +
  "        <Description> ... </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name=" ... " value="682">\n' +
  "         <Description> ... </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name=" ... " value="8">\n' +
  "        <Description> ... </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <UnknownQuantity name="u0" type="">\n' +
  "        <Description>...</Description>\n" +
  "    </UnknownQuantity>\n" +
  "    ...\n" +
  '    <Graph name="L1">\n' +
  '        <Path type=" ... " result=" ... " nodes=" ... "/>\n' +
  "        ...\n" +
  "    </Graph>\n" +
  "</Exercise>";

const exampleXMLSolvedCode =
  '<xml version="1.0" encoding="UTF-8">\n' +
  '<Exercise name="a_given_name">\n' +
  "    <Text>\n" +
  "        In a school there are 732 girls and 761 boys.\n" +
  "        682 more girls and 8 more boys joined the school.\n" +
  "        How many girls are there in the school now?\n" +
  "    </Text>\n" +
  '    <KnownQuantity name="Gp" value="732">\n' +
  "        <Description> number of girls </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name="Bp" value="761">\n' +
  "        <Description> number of boys </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name="Gm" value="682">\n' +
  "         <Description> number of girls more </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <KnownQuantity name=" Bm " value="8">\n' +
  "        <Description> number of boys more </Description>\n" +
  "    </KnownQuantity>\n" +
  '    <UnknownQuantity name="u0" type="result">\n' +
  "        <Description> number of girls now </Description>\n" +
  "    </UnknownQuantity>\n" +
  '    <Graph name="L1">\n' +
  '        <Path type=" addition " result=" u0 " nodes=" Gp,Gm "/>\n' +
  "    </Graph>\n" +
  "</Exercise>";
const instructionsModalPythonAndXML = () => {
  return (
    <>
      <p>{strings.help.introductionPythonXML}</p>
      <ul>
        <li>{strings.help.knownQuantitiesXML}</li>
        <li>{strings.help.unknownQuantitiesXML}</li>
        <li>
          {strings.help.pathsXML.map((element) => (
            <>
              {element}
              <br />
            </>
          ))}
        </li>
      </ul>
      <p>{strings.help.example}</p>
      <p>
        <b>
          In a school there are 732 girls and 761 boys. 682 more girls and 8
          more boys joined the school.. How many girls are there in the school
          now?
        </b>
      </p>
      <Highlight className="language-python">{examplePythonCode}</Highlight>
      <p>{strings.help.problemIsWrong}</p>
      <Highlight className="language-xml">{exampleXMLCode}</Highlight>
      <p>{strings.help.completedXML}</p>
      <Highlight className="language-xml">{exampleXMLSolvedCode}</Highlight>
    </>
  );
};
const instructionsModalOnlyPython = () => {
  return (
    <>
      <p>{strings.help.introductionPython}</p>
      <p>{strings.help.example}</p>
      <p>
        <b>
          In a school there are 732 girls and 761 boys. 682 more girls and 8
          more boys joined the school.. How many girls are there in the school
          now?
        </b>
      </p>
      <Highlight className="language-python">{examplePythonCode}</Highlight>
      <p>{strings.help.problemIsWrong}</p>
    </>
  );
};

const instructionsModalOnlyXML = () => {
  return (
    <>
      <p>{strings.help.introductionXML}</p>
      <ul>
        <li>{strings.help.knownQuantitiesXML}</li>
        <li>{strings.help.unknownQuantitiesXML}</li>
        <li>
          {strings.help.pathsXML.map((element) => (
            <>
              {element}
              <br />
            </>
          ))}
        </li>
      </ul>
      <p>{strings.help.example}</p>
      <p>
        <b>
          In a school there are 732 girls and 761 boys. 682 more girls and 8
          more boys joined the school.. How many girls are there in the school
          now?
        </b>
      </p>

      <Highlight className="language-xml">{exampleXMLCode}</Highlight>
      <p>{strings.help.completedXML}</p>
      <Highlight className="language-xml">{exampleXMLSolvedCode}</Highlight>
    </>
  );
};
const InstructionsModal = (props) => {
  const [treatment, setTreatment] = useState(1);
  useEffect(() => {
    console.log("rerendering");
    const axiosConfig = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axiosInstance.get("/api/v1/user/", axiosConfig).then((response) => {
      setTreatment(response.data.treatment);
    });
  }, []);
  const treatments = [
    instructionsModalOnlyXML(), // 1
    instructionsModalOnlyPython(), // 2
    instructionsModalPythonAndXML(), //3
  ];
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.openModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {strings.instruction}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{treatments[treatment - 1]}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="login-button"
          onClick={props.handleNavigation}
        >
          {strings.start_experiment}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default InstructionsModal;
