import { Modal } from "react-bootstrap";
import strings from "./Language";
const textEng = () => {
  return (
    <>
      Dear Participant,
      <br />
      Thank you for participating in our research survey. Before you proceed, we
      want to inform you about how your data will be collected and processed in
      compliance with the General Data Protection Regulation (GDPR). Please read
      this disclaimer carefully. <br />
      <b>Data Collection and Purpose:</b>
      <br />
      <ul>
        <li>
          <b>Personal Information</b>: This survey will collect your name and
          age for the purpose of demographic analysis. Your personal information
          will be stored separately from your survey responses and will not be
          linked to your answers in any way.
        </li>
        <li>
          <b>Survey Responses</b>: Your responses to the survey questions will
          be used solely for research purposes. We will use this data to analyze
          trends, patterns, and insights relevant to our research project.
        </li>
      </ul>
      <b>Data Protection and Privacy:</b>
      <ul>
        <li>
          <b>Confidentiality</b>: Your participation in this survey is
          voluntary, and all data you provide will be treated confidentially.
          Your name and age will be stored securely and separately from your
          survey responses. Only authorized researchers will have access to this
          data.
        </li>
        <li>
          <b>Data Retention</b>: We will retain your data for a period necessary
          to complete our research project. Afterward, the data will be securely
          archived and not used for any other purpose.
        </li>
        <li>
          <b>Data Modification or Deletion:</b> If you wish to modify or delete
          your personal information or survey responses, please contact Pablo
          Arnau at pablo.arnau@uv.es. Pablo Arnau is responsible for data
          management and can assist you with any data-related requests.
        </li>
      </ul>
      <b>Your Rights:</b>
      <ul>
        <li>
          <b>Right to Access</b>: You have the right to request access to your
          personal information and survey responses.
        </li>

        <li>
          <b>Right to Rectification</b>: You can request corrections to your
          personal information if it is inaccurate or incomplete.
        </li>

        <li>
          <b>Right to Erasure</b>: You have the right to request the deletion of
          your personal information and survey responses. Please note that
          deletion requests will be subject to our data retention policy.
        </li>
      </ul>
      <b>Consent:</b>
      <br />
      By proceeding with this survey, you acknowledge that you have read and
      understood this disclaimer and voluntarily consent to participate in our
      research study. You also acknowledge that your data may be used as
      described above. Your participation is greatly appreciated and contributes
      to the advancement of our research. If you have any questions or concerns
      about this survey or your data, please do not hesitate to contact Pablo
      Arnau at pablo.arnau@uv.es.
      <br />
      Thank you for your cooperation.
      <br />
      Sincerely,
      <br />
      <br />
      Pablo Arnau González
      <br />
      University of Valencia
      <br />
      pablo.arnau@uv.es
      <br />
    </>
  );
};
const textSpa = () => {
  return (
    <>
      Querido participante,
      <br />
      Gracias por participar en nuestra encuesta de investigación. Antes de
      continuar, deseamos informarte sobre cómo se recopilará y procesará tu
      información en cumplimiento con el Reglamento General de Protección de
      Datos (GDPR). Por favor, lee este descargo de responsabilidad
      detenidamente. <br />
      <b>Recopilación de Datos y Propósito:</b>
      <br />
      <ul>
        <li>
          <b>Información Personal</b>: Esta encuesta recopilará tu nombre y edad
          con el fin de realizar un análisis demográfico. Tu información
          personal se almacenará de manera independiente de tus respuestas en la
          encuesta y no se vinculará a tus respuestas de ninguna manera.
        </li>
        <li>
          <b>Respuestas de la Encuesta</b>: Tus respuestas a las preguntas de la
          encuesta se utilizarán únicamente con fines de investigación.
          Utilizaremos estos datos para analizar tendencias, patrones e
          información relevante para nuestro proyecto de investigación.
        </li>
      </ul>
      <b>Protección de Datos y Privacidad</b>
      <ul>
        <li>
          <b>Confidencialidad</b>: Tu participación en esta encuesta es
          voluntaria, y toda la información que proporciones se tratará de
          manera confidencial. Tu nombre y edad se almacenarán de manera segura
          y por separado de tus respuestas en la encuesta. Solo los
          investigadores autorizados tendrán acceso a estos datos.
        </li>
        <li>
          <b>Retención de Datos</b>: Conservaremos tus datos durante el tiempo
          necesario para completar nuestro proyecto de investigación.
          Posteriormente, los datos se archivarán de manera segura y no se
          utilizarán para ningún otro propósito.
        </li>
        <li>
          <b>Modificación o eliminación de datos:</b> Si deseas modificar o
          eliminar tu información personal o tus respuestas en la encuesta,
          comunícate con Pablo Arnau en pablo.arnau@uv.es. Pablo Arnau es el
          responsable de la gestión de datos y puede ayudarte con cualquier
          solicitud relacionada con los datos.
        </li>
      </ul>
      <b>Tus Derechos:</b>
      <ul>
        <li>
          <b>Derecho de Acceso</b>: Tienes derecho a solicitar acceso a tu
          información personal y a las respuestas de la encuesta.
        </li>

        <li>
          <b>Derecho de Rectificación</b>: Puedes solicitar correcciones a tu
          información personal si es inexacta o está incompleta.
        </li>

        <li>
          <b>Derecho al Olvido:</b> Tienes derecho a solicitar la eliminación de
          tu información personal y de las respuestas de la encuesta. Ten en
          cuenta que las solicitudes de eliminación estarán sujetas a nuestra
          política de retención de datos.
        </li>
      </ul>
      <b>Consentimiento:</b>
      <br />
      Al continuar con esta encuesta, reconoces que has leído y comprendido este
      descargo de responsabilidad y que participas voluntariamente en nuestro
      estudio de investigación. También reconoces que tus datos pueden ser
      utilizados tal como se describe anteriormente. Agradecemos mucho tu
      cooperación, ya que tu participación contribuye al avance de nuestra
      investigación. Si tienes alguna pregunta o inquietud sobre esta encuesta o
      tus datos, no dudes en ponerte en contacto con Pablo Arnau en
      pablo.arnau@uv.es. Gracias por tu colaboración.
      <br />
      <br />
      Atentamente,
      <br />
      <br />
      Pablo Arnau González
      <br />
      University of Valencia
      <br />
      pablo.arnau@uv.es
      <br />
    </>
  );
};

const TermsModal = (props) => {
  strings.getLanguage();
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...props}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {strings.legalText.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {strings.getLanguage() === "es" ? textSpa() : textEng()}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="login-button" onClick={props.onHide}>
          {strings.understood}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default TermsModal;
