import axios from 'axios';
import AppConfig from '../../AppConfig';

export const axiosInstance = axios.create({
    baseURL: AppConfig.url !== '' ? AppConfig.url : undefined,
    headers: {
      common: {
        Accept: 'application/json'
      }
    },
});

export default axiosInstance