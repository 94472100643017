import Editor from "@monaco-editor/react";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { axiosInstance } from "./utils/AxiosInstance";
import PopupWindow from "./utils/PopupWindow";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Highlight from "react-highlight";
import "../styles/CodePage.css";
import strings from "./utils/Language";
import { Stack } from "react-bootstrap/esm";
import ProgressBar from "react-bootstrap/ProgressBar";

const Cheatsheet = ({ show, doHide }) => {
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Header closeButton onHide={doHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          {strings.cheatsheet}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Highlight className="language-xml">
          {
            '<KnownQuantity name="Aa" value="42">\n    <Description>Description of the quantity</Description>\n</KnownQuantity>'
          }
        </Highlight>
        <p>
          {strings.cheatsheet_unknownQuantities}
          <Highlight className="language-xml">
            {
              '<UnknownQuantity name="Ab" type="">\n    <Description>Description of the quantity</Description>\n</UnknownQuantity>'
            }
          </Highlight>
        </p>
        <p>
          {strings.cheatsheet_paths}
          <Highlight className="language-xml">
            {'<Path type="Addition" result="Ab" nodes="Aa,Ac"/>'}
          </Highlight>
        </p>
      </Modal.Body>
    </Modal>
  );
};
const CodePage = () => {
  const [problem, setProblem] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const [modalShow, setModalShow] = useState(false);
  const [responseid, setResponseid] = useState("");
  const [hiddenPython, setPythonHidden] = useState(false);
  const [hiddenXML, setXMLHidden] = useState(false);
  const [canEditXML, setCanEditXML] = useState(false);
  const [canEditPython, setCanEditPython] = useState(true);
  const [pythonCompletionTime, setPythonCompletionTime] = useState(0);
  const [xmlCompletionTime, setXMLCompletionTime] = useState(0);
  const [pythonResponse, setPythonResponse] = useState("");
  const [xmlResponse, setXMLResponse] = useState("");
  const [isPythonReady, setPythonReady] = useState(false);
  const [isXMLReady, setXMLReady] = useState(false);
  const [showCheatsheet, setShowCheatsheet] = useState(false);
  const [progress, setProgress] = useState({ current: 1, total: 10 });
  const navigate = useNavigate();
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }, []);

  const getProblem = useCallback(() => {
    axiosInstance
      .get("/api/v1/problem/", axiosConfig)
      .then((response) => {
        setProblem(response.data.problem);
        setPythonHidden(response.data.treatment.hidePython);
        setXMLHidden(response.data.treatment.hideXML);
        if (
          response.data.treatment.hidePython &&
          !response.data.treatment.hideXML
        ) {
          setCanEditXML(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.request.status === 404) {
          navigate("/end");
        }
      });
  }, [navigate, axiosConfig]);

  useEffect(() => {
    getProblem();
  }, [getProblem]);

  useEffect(() => {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axiosInstance
      .get("/api/v1/problem/progress", axiosConfig)
      .then((response) => {
        setProgress(response.data);
      });
  }, [getProblem]);
  useEffect(() => {
    setPythonResponse(problem.python);
    setXMLResponse(problem.xml);
  }, [problem.python, problem.xml]);

  const doSubmit = useCallback(() => {
    let bodyPayload = {
      problem_id: problem.id,
      python: pythonResponse,
      xml: xmlResponse,
      python_completion_time: pythonCompletionTime,
      xml_completion_time: xmlCompletionTime,
      ini_time: startTime,
      end_time: Date.now(),
    };

    axiosInstance
      .post("/api/v1/response/", bodyPayload, axiosConfig)
      .then((response) => {
        setResponseid(response.data.id);
        setStartTime(Date.now());
        setModalShow(true);
      })
      .catch(() => {
        console.log("error");
      });
  }, [
    problem.id,
    pythonCompletionTime,
    pythonResponse,
    startTime,
    xmlCompletionTime,
    xmlResponse,
    axiosConfig,
  ]);

  const sendResponse = () => {
    if (problem.id === undefined) return;
    if (!hiddenPython && !hiddenXML && isPythonReady && isXMLReady) {
      setPythonReady(false);
      setXMLReady(false);
      doSubmit();
      return;
    }
    if (!hiddenPython && hiddenXML && isPythonReady) {
      setPythonReady(false);
      setXMLReady(false);
      doSubmit();
      return;
    }
    if (hiddenPython && !hiddenXML && isXMLReady) {
      setPythonReady(false);
      setXMLReady(false);
      doSubmit();
    }
  };

  useEffect(sendResponse, [
    problem.id,
    hiddenPython,
    hiddenXML,
    isPythonReady,
    isXMLReady,
    doSubmit,
  ]);

  const handlePythonEditorChange = (value, event) => {
    setPythonResponse(value);
  };

  const handlePythonSubmit = (e) => {
    setPythonCompletionTime(Date.now());
    if (!hiddenXML) {
      setCanEditXML(true);
      setCanEditPython(false);
    }
    setPythonReady(true);
  };
  const handleXMLFormSubmit = (e) => {
    setXMLCompletionTime(Date.now());
    setXMLReady(true);
  };
  const handleXMLEditorChange = (value, event) => {
    setXMLResponse(value);
  };
  const doShowCheatsheet = () => {
    const event = {
      type: "cheatsheet",
      problem_id: problem.id,
      timestamp: Date.now(),
      content: "showed",
    };
    axiosInstance
      .post("/api/v1/event/", event, axiosConfig)
      .catch((reason) => console.log(reason));
    setShowCheatsheet(true);
  };
  const doHideCheatsheet = () => {
    const event = {
      type: "cheatsheet",
      problem_id: problem.id,
      timestamp: Date.now(),
      content: "hidden",
    };
    axiosInstance
      .post("/api/v1/event/", event, axiosConfig)
      .catch((reason) => console.log(reason));
    setShowCheatsheet(false);
  };

  return (
    <div className="parent_container">
      <div className="problem_section">
        <h1>{problem.statement}</h1>
      </div>
      <Container fluid>
        <Row>
          <Col className="editor_section" hidden={hiddenPython}>
            <h1>Python</h1>
            <Editor
              id="code_editor"
              height="50vh"
              // weight="100vh"
              theme="vs-black"
              defaultLanguage="python"
              value={problem.python}
              options={{
                contextmenu: false,
                minimap: { enabled: false },
                fontSize: "18px",
                readOnly: !canEditPython,
                wordWrap: "on",
              }}
              onChange={handlePythonEditorChange}
            />
            <Stack direction="horizontal">
              <div className="ms-auto">
                <Button
                  variant="success"
                  disabled={!canEditPython}
                  onClick={handlePythonSubmit}
                  size={"lg"}
                >
                  {strings.run}
                </Button>
              </div>
            </Stack>
          </Col>
          <Col className="editor_section" hidden={hiddenXML}>
            <h1>XML</h1>
            <Editor
              id="xml_editor"
              height="50vh"
              theme="vs-black"
              defaultLanguage="xml"
              value={problem.xml}
              options={{
                contextmenu: false,
                minimap: { enabled: false },
                fontSize: "18px",
                readOnly: !canEditXML,
                wordWrap: "on",
              }}
              onChange={handleXMLEditorChange}
            />
            <Stack direction="horizontal">
              <div className="ms-auto">
                <Button
                  variant="primary"
                  disabled={!canEditXML}
                  size={"lg"}
                  onClick={doShowCheatsheet}
                >
                  {strings.cheatsheet}
                </Button>{" "}
                &nbsp;
                <Button
                  variant="success"
                  disabled={!canEditXML}
                  onClick={handleXMLFormSubmit}
                  size={"lg"}
                >
                  {strings.run}
                </Button>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
      <ProgressBar
        animated
        now={progress.current + 1}
        max={progress.total}
        label={`${progress.current} / ${progress.total}`}
      />
      <Cheatsheet show={showCheatsheet} doHide={doHideCheatsheet} />

      <PopupWindow
        responseid={responseid}
        show={modalShow}
        onHide={setModalShow}
      />
    </div>
  );
};

export default CodePage;
